<template>
  <div>
    <h2 class="m-0">Submit NOC Request</h2>
    <hr />
    <b-form @submit.prevent>
      <b-tabs content-class="py-1" fill>
        <validation-observer ref="nocSubmitDetailFormValidation">
          <b-tab no-body>
            <div>
              <h2 class="font-weight-bolder mb-2">Basic Data</h2>
              <b-row>
                <b-col md="4">
                  <b-form-group>
                    <label>CNIC</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="cnic"
                      rules="required|integer|positive|length:13"
                    >
                      <b-form-input
                        id="cnic"
                        v-model="cnic"
                        disabled
                        :state="errors.length > 0 ? false : null"
                        placeholder="1234567890001"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group>
                    <label>Name</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        disabled
                        v-model="name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group>
                    <label>Contact No.</label>
                    <!-- <span class="text-danger">*</span> -->
                    <validation-provider
                      #default="{ errors }"
                      name="Contact No"
                      rules="integer|length:11"
                    >
                      <b-form-input
                        id="contactNo"
                        disabled
                        v-model="contactNo"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Contact No"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group>
                    <label>Address</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Address"
                      :rules="{
                        required,
                        regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9\s,\'@#\-!&*]+$/,
                      }"
                    >
                      <b-form-input
                        id="address"
                        v-model="address"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Address"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Write your complete address 
                        house #, street/mohala, Tehsil "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label-for="district">
                    <validation-provider
                      #default="{ errors }"
                      name="District"
                      rules="required"
                    >
                      <label>District</label><span class="text-danger">*</span>
                      <v-select
                        v-model="district"
                        placeholder="District"
                        :options="districts"
                        label="name"
                        class="text-black v-style-chooser"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Plot Size"
                      rules="required"
                    >
                      <label>Plot Size</label><span class="text-danger">*</span>
                      <v-select
                        v-model="plot_size"
                        :options="plotSizes"
                        placeholder="Plot Size"
                        label="name"
                        class="text-black v-style-chooser"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="4">
                  <b-form-group>
                    <label>Corps/Regiment</label>
                    <span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Regiment / Corps"
                      rules="required"
                    >
                      <v-select
                        id="Regimenttype"
                        v-model="regiment"
                        :options="getCorps"
                        placeholder="Regiment / Corps"
                        label="name"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
              </b-row>
              <b-row>
                <!-- <b-col md="4">
                  <b-form-group>
                    <label>Benefit Type</label
                    ><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Benefit Type"
                      rules="required"
                    >
                      <v-select
                        id="type"
                        v-model="benefit_type"
                        :options="getBenefitTypes"
                        placeholder="Benefit Type"
                        label="name"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <!-- <b-col md="4">
                  <b-form-group>
                    <label>SVC Benefit No</label
                    ><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="SVC Benefit No"
                      rules="required"
                      class="text-black"
                    >
                      <v-select
                        id="type"
                        v-model="svc_benefit_type"
                        :options="getBenefitNumbers"
                        placeholder="SVC Benefit No"
                        label="name"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col md="4" class="mt-1">
                  <b-form-group
                    label-for="isCommercial"
                    v-slot="{ ariaDescribedby }"
                  >
                    <label>Residential / Commercial</label>
                    <b-form-radio-group
                      id="isCommercial"
                      v-model="isCommercial"
                      :aria-describedby="ariaDescribedby"
                      class="text-black"
                    >
                      <b-form-radio v-model="isCommercial" :value="false">
                        Residential
                      </b-form-radio>
                      <b-form-radio v-model="isCommercial" :value="true">
                        Commercial
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>DHA</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="plot_society"
                      rules="required"
                    >
                      <v-select
                        v-model="plot_society"
                        :options="societyList"
                        placeholder="Society"
                        label="name"
                        class="text-black v-style-chooser"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group>
                    <label>Plot No.</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="plot_no"
                      :rules="{ required, regex: /^[A-Za-z0-9\s]{1,50}$/ }"
                    >
                      <b-form-input
                        id="plot_no"
                        v-model="plot_no"
                        placeholder="Plot No."
                        class="text-black"
                        v-b-tooltip.hover
                        title="Enter your plot number e.g. 123"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group>
                    <label>Street</label
                    ><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Street"
                      :rules="{ required }"
                    >
                      <b-form-input
                        id="street"
                        v-model="street"
                        placeholder="Street"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Use following format e.g.
                        Street 1"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Sector</label
                    ><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Sector"
                      :rules="{ required }"
                    >
                      <b-form-input
                        id="sector"
                        v-model="sector"
                        placeholder="Sector"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Use following format e.g.
                        Sector A"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Phase</label
                    ><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Phase"
                      :rules="{ required }"
                    >
                      <b-form-input
                        id="phase"
                        v-model="phase"
                        placeholder="Phase"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Use following format e.g.
                        phase 1"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label-for="documents">
                    <validation-provider #default="{ errors }" name="Document">
                      <label>Document (PDF only)</label>
                      <b-form-file
                        ref="fileInput"
                        id="documents"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        class="text-black"
                        accept=".pdf"
                        @change="upload"
                      />
                      <small class="text-danger" v-if="documentError === 1"
                        >Max {{ maxFilesCount }} files are allowed</small
                      >
                      <small class="text-danger" v-if="documentError === 2"
                        >Each file size should be less than
                        {{ maxFileSize }} MB</small
                      >
                      <small class="text-danger" v-if="documentError === 3"
                        >Only pdf is allowed</small
                      >
                      <div class="d-flex flex-wrap">
                        <b-badge
                          pill
                          variant="primary"
                          v-for="(file, index) in uploadedFiles"
                          :key="index"
                          class="mr-50 mt-50"
                        >
                          {{ file.name }}
                          <feather-icon
                            size="24"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeFile(index)"
                          />
                        </b-badge>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-form-group>
            <!-- <b-button
              id="draft"
              type="submit"
              variant="outline-success"
              pill
              class="mr-1 text-left"
              @click="saveAsDraft"
            >
              Save as Draft
            </b-button> -->
            <b-button
              id="submit"
              type="submit"
              variant="primary"
              pill
              class="mr-1"
              @click="validationForm"
            >
              Submit
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="reset"
              pill
            >
              Reset
            </b-button>
          </b-form-group>
        </validation-observer>
      </b-tabs>
    </b-form>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>

  <!-- </b-modal> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import util from "@/util.js";
import { heightTransition } from "@core/mixins/ui/transition";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    VueSelectPaginated,
    VuePerfectScrollbar,
    draggable,
  },
  mixins: [util, heightTransition],
  props: { modalClosed: "modalClosed" },
  data() {
    return {
      show: false,
      required,
      perfectScrollbarSettings: {
        maxScrollbarLength: 100,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
      },
      title: "NOC",
      subject: "No Object Certificate (NOC) - for Sale of DHA Plot",
      content: "-",
      comment: "",
      draft: false,
      maxCommentCount: 400,
      selectedMarkType: true,
      selectedOrganization: null,
      availableUserList: [],
      markedUserList: [],
      documentError: 0,
      maxFilesCount: 5,
      maxFileSize: 5,
      uploadedFiles: [],
      confidentialityType: false,
      option: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            ["bold", "italic", "underline", "strike"], // toggled buttons

            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
          ],
        },
      },
      streetOrLane: [
        { value: "St", text: "Street", name: "Street" },
        { value: "Ln", text: "Lane", name: "Lane" },
      ],
      streetLane: null,
      prefix: "",
      armyNo: "",
      cnic: "",
      name: "",
      address: "",
      district: "",
      contactNo: "",
      benefit_type: null,
      isServing: false,
      isCommercial: false,
      svc_benefit_type: "",
      regiment: "",
      plot_size: "",
      plot_society: "",
      plot_location: "",
      plot_no: "",
      street: "",
      sector: "",
      phase: "",
      detailForm: false,
      type: { value: 5, name: "User Generated AFP" },
      districts: [],
      plotSizes: [],

      corps: [],
      // rankList: [
      //   { title: "Lt Gen" },
      //   { title: "Maj Gen" },
      //   { title: "Brig" },
      //   { title: "Col" },
      //   { title: "Lt Col" },
      //   { title: "Maj" },
      //   { title: "Capt" },
      //   { title: "Lt" },
      //   { title: "2nd Lt" },
      //   { title: "SM" },
      //   { title: "Sub" },
      //   { title: "N Sub" },
      //   { title: "Hav" },
      //   { title: "Nk" },
      //   { title: "Lnk" },
      //   { title: "Sep" },
      // ],
      benefitTypeList: [],
      benefitNumbers: [],
      societyList: [],

      priority: { value: "Normal", name: "Normal" },
      priorities: [
        { value: "Normal", name: "Normal" },
        { value: "Flash", name: "Flash" },
      ],
    };
  },
  async mounted() {
    await this.getNocFormFields();
    const userData = await this.getLoggedInUser;
    this.cnic = userData.cnic;
    this.name = userData.name;
    this.contactNo = userData.mobile;
  },
  methods: {
    ...mapActions({
      getUser: "appData/getUser",
      getUsers: "appData/getUsers",
      getUsersUnPaginated: "appData/getUsersUnPaginated",
      createNoc: "appData/createENoc",
      getNOCFields: "appData/getNOCFields",
    }),
    async saveAsDraft() {
      this.draft = true;
      await this.validationForm();
    },
    repeateAgain(type) {
      if (type === 1) {
        this.benefitPlots.push({
          id: (this.nextTodoBenefitPlotsId += this.nextTodoBenefitPlotsId),
        });

        if (this.benefitPlots.length > 1) {
          this.$nextTick(() => {
            this.trAddHeight(this.$refs.row[0].offsetHeight, type);
          });
        }
      } else if (type === 2) {
        this.feedbacks.push({
          id: (this.nextTodoFeedbacksId += this.nextTodoFeedbacksId),
        });

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight, type);
        });
      } else if (type === 3) {
        this.family.push({
          id: (this.nextTodoFamilyId += this.nextTodoFamilyId),
          isMarried: false,
          isAlive: true,
        });

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight, type);
        });
      } else if (type === 4) {
        this.property.push({
          id: (this.nextTodoPropertyId += this.nextTodoPropertyId),
        });

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight, type);
        });
      }
    },

    removeItem(index, type) {
      if (type === 1) {
        this.benefitPlots.splice(index, 1);
        this.trTrimHeight(this.$refs.row[0].offsetHeight, type);
      } else if (type === 2) {
        this.feedbacks.splice(index, 1);
        this.trTrimHeight(this.$refs.row[0].offsetHeight, type);
      } else if (type === 3) {
        this.family.splice(index, 1);
        this.trTrimHeight(this.$refs.row[0].offsetHeight, type);
      } else if (type === 4) {
        this.property.splice(index, 1);
        this.trTrimHeight(this.$refs.row[0].offsetHeight, type);
      }
    },
    async onOrganizationChange(organization) {
      if (!organization) {
        this.availableUserList = [];
        return;
      }

      try {
        const res = await this.getUsersUnPaginated({
          organization: organization.id,
        });
        this.availableUserList = res.data;
      } catch (error) {
        this.displayError(error);
      }
      this.selectedOrganizationId = null;
    },

    async upload(event) {
      this.documentError = 0;
      const files = event.target.files;
      if (this.uploadedFiles.length + files.length > this.maxFilesCount) {
        this.documentError = 1;
        return;
      }
      if (!files[0].name.includes(".pdf")) {
        this.documentError = 3;
        return;
      }

      const filesToAdd = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > this.maxFileSize * 1000 * 1024) {
          this.documentError = 2;
          return;
        }

        filesToAdd.push(file);
      }

      this.uploadedFiles = [...this.uploadedFiles, ...filesToAdd];
    },
    removeFile(index) {
      this.documentError = 0;
      this.uploadedFiles.splice(index, 1);
    },

    onListChange({ removed }) {
      if (removed) {
        const users = [];
        let removedFirst = false;

        for (let i = 0; i < this.availableUserList.length; i++) {
          const element = this.availableUserList[i];
          if (element.id === removed.element.id && !removedFirst) {
            removedFirst = true;
          } else {
            users.push(element);
          }
        }

        this.availableUserList = users;
      }
    },
    async saveDraft() {
      this.draft = true;
      await this.validationForm();
    },

    async getNocFormFields() {
      try {
        const res = await this.getNOCFields();

        if (res.status === 200) {
          this.districts = res.data.districts;
          this.plotSizes = res.data.plot_sizes;
          // this.corps = res.data.regiments;
          this.societyList = res.data.plot_societies;
          // this.benefitTypeList = res.data.benefit_types;
          // this.benefitNumbers = res.data.svc_benefit_types;
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    async validationForm() {
      let success = true;
      if (success) {
        this.homeForm = false;
        success = await this.$refs.nocSubmitDetailFormValidation.validate();
        if (success) {
          this.detailForm = false;
          await this.submit();
        } else {
          this.detailForm = true;
        }
      } else {
        this.homeForm = true;
      }
    },
    async submit() {
      this.show = true;

      if (this.documentError === 0) {
        try {
          let plotType = 1;
          if(this.isCommercial){
            plotType = 2;
          }else{
            plotType = 1;
          }
          const dataObj = {
            title: this.title,
            subject: this.subject,
            content: this.content,
            type: this.type.value,
            draft: this.draft,
            history: history,

            cnic: this.cnic,
            name: this.name,
            address: this.address,
            district_id: this.district.id,
            contact_no: this.contactNo,

            plot_size_id: this.plot_size.id,
            plot_society_id: this.plot_society.id,
            plot_location: this.plot_location,
            plot_no: this.plot_no,
            street: this.street,
            sector: this.sector,
            phase: this.phase,
            plot_type: plotType,

            is_serving: false,
            is_commercial: this.isCommercial,
            draft: this.draft,

            currently_marked: 99999999,
            priority: this.priority.value,
            created_by: this.getLoggedInUser.id,
            updated_by: this.getLoggedInUser.id,
          };

          let formData = new FormData();
          this.uploadedFiles.map((file, index) => {
            formData.append(`file${index}`, file);
          });

          formData.append("data", JSON.stringify(dataObj));

          // formData.append('subject', this.subject)
          // formData.append('content', this.content)
          // formData.append('type', this.type.value)
          // formData.append('draft', this.draft)
          // formData.append('history', this.history)

          // formData.append('cnic', this.cnic)
          // formData.append('name', this.name)
          // formData.append('address', this.address)
          // formData.append('district', this.district.value)
          // formData.append('contact_no', this.contact_no)

          // formData.append('benefit_type', this.benefit_type.value)
          // formData.append('svc_benefit_type', this.svc_benefit_type)
          // formData.append('regiment', this.regiment)
          // formData.append('plot_size', this.plot_size.value)
          // formData.append('plot_society', this.plot_society.value)
          // formData.append('plot_location', this.plot_location)
          // formData.append('plot_no', this.plot_no)
          // formData.append('street_sec_p', this.street_sec_p)

          // formData.append('currently_marked', 99999999)
          // formData.append('priority', this.priority.value)
          // formData.append('created_by', this.getLoggedInUser.id)
          // formData.append('updated_by', this.getLoggedInUser.id)

          const res = await this.createNoc(formData);

          if (res.status === 200) {
            this.displaySuccess(res.data.msg);
            this.show = false;
            this.reset();
            this.$refs.nocSubmitDetailFormValidation.reset();
            this.$nextTick(() => {
              this.$bvModal.hide("noc-submit-modal");
            });

            this.$emit("modalClosed");
          }
        } catch (error) {
          this.show = false;
          this.reset();
          this.displayError(error);
        }
      } else {
        this.$swal({
          title: `File size should be less than ${this.maxFileSize} MB or Total files should be less than ${this.maxFilesCount}`,
          icon: "error",
          timer: 3000,
        });
        this.documentError = 0;
        this.uploadedFiles = [];
        this.$refs.fileInput.reset();
        this.$refs.nocSubmitDetailFormValidation.reset();
      }
    },
    reset() {
      this.title = "";
      this.subject = "";
      this.files = [];
      this.content = "";
      this.comment = "";
      this.address = "";
      this.district = "";
      this.benefit_type = "";
      this.svc_benefit_type = "";
      this.isServing = false;
      this.isCommercial = false;
      this.benefitPlots = [];
      this.homeForm = false;
      this.detailForm = false;
      this.type = "UserFwd";

      this.regiment = "";
      this.plot_size = "";
      this.plot_society = "";
      this.plot_location = "";
      this.plot_no = "";
      this.street_sec_p = "";
      this.uploadedFiles = [];
      this.$refs.fileInput.reset();

      // this.prefixList = [];
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
    // getNocTypes() {
    //   return this.nocTypes.map((type) => {
    //     return {
    //       value: type.id,
    //       name: type.name,
    //     };
    //   });
    // },
    // getBenefitTypes() {
    //   return this.benefitTypeList.map((type) => {
    //     return {
    //       value: type.title,
    //       name: type.title,
    //     };
    //   });
    // },

    // getArmsSvc() {
    //   return this.armsSvcList.map((type) => {
    //     return {
    //       value: type.title,
    //       name: type.title,
    //     };
    //   });
    // },

    // getClearStatuses() {
    //   return this.clearStatusList.map((type) => {
    //     return {
    //       value: type.title,
    //       name: type.title,
    //     };
    //   });
    // },

    // getDepartments() {
    //   return this.departmentList.map((type) => {
    //     return {
    //       value: type.title,
    //       name: type.title,
    //     };
    //   });
    // },

    // getDistricts() {
    //   return this.districtList.map((type) => {
    //     return {
    //       value: type.title,
    //       name: type.title,
    //     };
    //   });
    // },

    // getNocIssuedNos() {
    //   return this.nocIssuedNoList.map((type) => {
    //     return {
    //       value: type.title,
    //       name: type.title,
    //     };
    //   });
    // },

    // getPlotSizes() {
    //   return this.plotSizeList.map((type) => {
    //     return {
    //       value: type.title,
    //       name: type.title,
    //     };
    //   });
    // },

    // getPrefixes() {
    //   return this.prefixList.map((type) => {
    //     return {
    //       value: type.prefix,
    //       name: type.prefix,
    //     };
    //   });
    // },

    getCorps() {
      return this.corps.map((type) => {
        return {
          value: type.id,
          name: type.name,
        };
      });
    },

    // getBenefitTypes() {
    //   return this.benefitTypeList.map((type) => {
    //     return {
    //       value: type.id,
    //       name: type.name,
    //     };
    //   });
    // },

    // getBenefitNumbers() {
    //   return this.benefitNumbers.map((type) => {
    //     return {
    //       value: type.id,
    //       name: type.name,
    //     };
    //   });
    // },

    getDistricts() {
      return this.districts.map((type) => {
        return {
          value: type.id,
          name: type.name,
        };
      });
    },

    getPlotSize() {
      return this.plotSizes.map((type) => {
        return {
          value: type.id,
          name: type.name,
        };
      });
    },

    getDHAs() {
      return this.societyList.map((type) => {
        return {
          value: type.id,
          name: type.name,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";

.scroll-area-size {
  height: 30vh;
}

.scroll-area-size ul {
  height: 100%;
}

.modal-size {
  height: 65vh;
}

.quill-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.quill-editor {
  overflow-y: hidden;
}

.vSelectStyle .vs__deselect {
  fill: #fff !important;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
