var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"m-0"},[_vm._v("Submit NOC Request")]),_c('hr'),_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-tabs',{attrs:{"content-class":"py-1","fill":""}},[_c('validation-observer',{ref:"nocSubmitDetailFormValidation"},[_c('b-tab',{attrs:{"no-body":""}},[_c('div',[_c('h2',{staticClass:"font-weight-bolder mb-2"},[_vm._v("Basic Data")]),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("CNIC")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"cnic","rules":"required|integer|positive|length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cnic","disabled":"","state":errors.length > 0 ? false : null,"placeholder":"1234567890001"},model:{value:(_vm.cnic),callback:function ($$v) {_vm.cnic=$$v},expression:"cnic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Name")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":"","state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Contact No.")]),_c('validation-provider',{attrs:{"name":"Contact No","rules":"integer|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contactNo","disabled":"","state":errors.length > 0 ? false : null,"placeholder":"Contact No"},model:{value:(_vm.contactNo),callback:function ($$v) {_vm.contactNo=$$v},expression:"contactNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Address")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Address","rules":{
                      required: _vm.required,
                      regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9\s,\'@#\-!&*]+$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-black",attrs:{"id":"address","state":errors.length > 0 ? false : null,"placeholder":"Address","title":"Write your complete address \n                      house #, street/mohala, Tehsil "},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"district"}},[_c('validation-provider',{attrs:{"name":"District","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('label',[_vm._v("District")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('v-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-black v-style-chooser",attrs:{"placeholder":"District","options":_vm.districts,"label":"name","title":"Select an option from the dropdown menu"},model:{value:(_vm.district),callback:function ($$v) {_vm.district=$$v},expression:"district"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Plot Size","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('label',[_vm._v("Plot Size")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('v-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-black v-style-chooser",attrs:{"options":_vm.plotSizes,"placeholder":"Plot Size","label":"name","title":"Select an option from the dropdown menu"},model:{value:(_vm.plot_size),callback:function ($$v) {_vm.plot_size=$$v},expression:"plot_size"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row'),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"isCommercial"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var ariaDescribedby = ref.ariaDescribedby;
return [_c('label',[_vm._v("Residential / Commercial")]),_c('b-form-radio-group',{staticClass:"text-black",attrs:{"id":"isCommercial","aria-describedby":ariaDescribedby},model:{value:(_vm.isCommercial),callback:function ($$v) {_vm.isCommercial=$$v},expression:"isCommercial"}},[_c('b-form-radio',{attrs:{"value":false},model:{value:(_vm.isCommercial),callback:function ($$v) {_vm.isCommercial=$$v},expression:"isCommercial"}},[_vm._v(" Residential ")]),_c('b-form-radio',{attrs:{"value":true},model:{value:(_vm.isCommercial),callback:function ($$v) {_vm.isCommercial=$$v},expression:"isCommercial"}},[_vm._v(" Commercial ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("DHA")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"plot_society","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-black v-style-chooser",attrs:{"options":_vm.societyList,"placeholder":"Society","label":"name","title":"Select an option from the dropdown menu"},model:{value:(_vm.plot_society),callback:function ($$v) {_vm.plot_society=$$v},expression:"plot_society"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Plot No.")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"plot_no","rules":{ required: _vm.required, regex: /^[A-Za-z0-9\s]{1,50}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-black",attrs:{"id":"plot_no","placeholder":"Plot No.","title":"Enter your plot number e.g. 123"},model:{value:(_vm.plot_no),callback:function ($$v) {_vm.plot_no=$$v},expression:"plot_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Street")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Street","rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-black",attrs:{"id":"street","placeholder":"Street","title":"Use following format e.g.\n                      Street 1"},model:{value:(_vm.street),callback:function ($$v) {_vm.street=$$v},expression:"street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Sector")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Sector","rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-black",attrs:{"id":"sector","placeholder":"Sector","title":"Use following format e.g.\n                      Sector A"},model:{value:(_vm.sector),callback:function ($$v) {_vm.sector=$$v},expression:"sector"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Phase")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Phase","rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-black",attrs:{"id":"phase","placeholder":"Phase","title":"Use following format e.g.\n                      phase 1"},model:{value:(_vm.phase),callback:function ($$v) {_vm.phase=$$v},expression:"phase"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"documents"}},[_c('validation-provider',{attrs:{"name":"Document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('label',[_vm._v("Document (PDF only)")]),_c('b-form-file',{ref:"fileInput",staticClass:"text-black",attrs:{"id":"documents","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":".pdf"},on:{"change":_vm.upload}}),(_vm.documentError === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("Max "+_vm._s(_vm.maxFilesCount)+" files are allowed")]):_vm._e(),(_vm.documentError === 2)?_c('small',{staticClass:"text-danger"},[_vm._v("Each file size should be less than "+_vm._s(_vm.maxFileSize)+" MB")]):_vm._e(),(_vm.documentError === 3)?_c('small',{staticClass:"text-danger"},[_vm._v("Only pdf is allowed")]):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.uploadedFiles),function(file,index){return _c('b-badge',{key:index,staticClass:"mr-50 mt-50",attrs:{"pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(file.name)+" "),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"24","icon":"XIcon"},on:{"click":function($event){return _vm.removeFile(index)}}})],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)]),_c('b-form-group',[_c('b-button',{staticClass:"mr-1",attrs:{"id":"submit","type":"submit","variant":"primary","pill":""},on:{"click":_vm.validationForm}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary","pill":""},on:{"click":_vm.reset}},[_vm._v(" Reset ")])],1)],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.show,"spinner-variant":"primary","no-wrap":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }