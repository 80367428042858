<template>
  <b-modal
    id="enoc-edit-modal"
    title="NOC Edit"
    centered
    hide-footer
    size="xl"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">NOC Details</h2>
    </template>

    <b-form @submit.prevent>
      <b-tabs content-class="py-1" fill>
        <validation-observer ref="nocEditValidation">
          <b-tab no-body>
            <div>
              <b-row>
                <b-col md="4">
                  <b-form-group>
                    <label>CNIC</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="cnic"
                      rules="required|integer|positive|length:13"
                    >
                      <b-form-input
                        id="cnic"
                        v-model="cnic"
                        disabled
                        :state="errors.length > 0 ? false : null"
                        placeholder="1234567890001"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group>
                    <label>Name</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        disabled
                        v-model="name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group>
                    <label>Contact No.</label>
                    <!-- <span class="text-danger">*</span> -->
                    <validation-provider
                      #default="{ errors }"
                      name="Contact No"
                      rules="integer|length:11"
                    >
                      <b-form-input
                        id="contactNo"
                        disabled
                        v-model="contactNo"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Contact No"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group>
                    <label>Address</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Address"
                      :rules="{
                        required,
                        regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9\s,\'@#\-!&*]+$/,
                      }"
                    >
                      <b-form-input
                        id="address"
                        v-model="address"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Address"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Write your complete address 
                        house #, street/mohala, Tehsil "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label-for="district">
                    <validation-provider
                      #default="{ errors }"
                      name="District"
                      rules="required"
                    >
                      <label>District</label><span class="text-danger">*</span>
                      <v-select
                        v-model="district"
                        placeholder="District"
                        :options="districts"
                        label="name"
                        class="text-black v-style-chooser"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Plot Size"
                      rules="required"
                    >
                      <label>Plot Size</label><span class="text-danger">*</span>
                      <v-select
                        v-model="plot_size"
                        :options="plotSizes"
                        placeholder="Plot Size"
                        label="name"
                        class="text-black v-style-chooser"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="4">
                  <b-form-group>
                    <label>Corps/Regiment</label>
                    <span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Regiment / Corps"
                      rules="required"
                    >
                      <v-select
                        id="Regimenttype"
                        v-model="regiment"
                        :options="getCorps"
                        placeholder="Regiment / Corps"
                        label="name"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
              </b-row>
              <b-row>
                <!-- <b-col md="4">
                  <b-form-group>
                    <label>Benefit Type</label
                    ><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Benefit Type"
                      rules="required"
                    >
                      <v-select
                        id="type"
                        v-model="benefit_type"
                        :options="getBenefitTypes"
                        placeholder="Benefit Type"
                        label="name"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <!-- <b-col md="4">
                  <b-form-group>
                    <label>SVC Benefit No</label
                    ><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="SVC Benefit No"
                      rules="required"
                      class="text-black"
                    >
                      <v-select
                        id="type"
                        v-model="svc_benefit_type"
                        :options="getBenefitNumbers"
                        placeholder="SVC Benefit No"
                        label="name"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col md="4" class="mt-1">
                  <b-form-group
                    label-for="isCommercial"
                    v-slot="{ ariaDescribedby }"
                  >
                    <label>Residential / Commercial</label>
                    <b-form-radio-group
                      id="isCommercial"
                      v-model="isCommercial"
                      :aria-describedby="ariaDescribedby"
                      class="text-black"
                    >
                      <b-form-radio v-model="isCommercial" :value="false">
                        Residential
                      </b-form-radio>
                      <b-form-radio v-model="isCommercial" :value="true">
                        Commercial
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>DHA</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="plot_society"
                      rules="required"
                    >
                      <v-select
                        v-model="plot_society"
                        :options="societyList"
                        placeholder="Society"
                        label="name"
                        class="text-black v-style-chooser"
                        v-b-tooltip.hover
                        title="Select an option from the dropdown menu"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group>
                    <label>Plot No.</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="plot_no"
                      :rules="{ required, regex: /^[A-Za-z0-9\s]{1,50}$/ }"
                    >
                      <b-form-input
                        id="plot_no"
                        v-model="plot_no"
                        placeholder="Plot No."
                        class="text-black"
                        v-b-tooltip.hover
                        title="Enter your plot number e.g. 123"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group>
                    <label>Street</label
                    ><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Street"
                      :rules="{ required }"
                    >
                      <b-form-input
                        id="street"
                        v-model="street"
                        placeholder="Street"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Use following format e.g.
                        Street 1"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Sector</label
                    ><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Sector"
                      :rules="{ required }"
                    >
                      <b-form-input
                        id="sector"
                        v-model="sector"
                        placeholder="Sector"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Use following format e.g.
                        Sector A"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Phase</label
                    ><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Phase"
                      :rules="{ required }"
                    >
                      <b-form-input
                        id="phase"
                        v-model="phase"
                        placeholder="Phase"
                        class="text-black"
                        v-b-tooltip.hover
                        title="Use following format e.g.
                        phase 1"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-form-group>
            <!-- <b-button
              id="draft"
              type="submit"
              variant="outline-success"
              pill
              class="mr-1 text-left"
              @click="saveAsDraft"
            >
              Save as Draft
            </b-button> -->
            <b-button
              id="submit"
              type="submit"
              variant="primary"
              pill
              class="mr-1"
              @click="validationForm"
            >
              Update
            </b-button>
          </b-form-group>
        </validation-observer>
      </b-tabs>
    </b-form>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "../../util";
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    nocData: Object,
  },
  mixins: [util],
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      required,
      perfectScrollbarSettings: {
        maxScrollbarLength: 100,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
      },
      title: "NOC",
      subject: "No Object Certificate (NOC) - for Sale of DHA Plot",
      content: "-",
      comment: "",
      draft: false,
      maxCommentCount: 400,
      selectedMarkType: true,
      selectedOrganization: null,
      availableUserList: [],
      markedUserList: [],
      documentError: 0,
      maxFilesCount: 15,
      maxFileSize: 5,
      files: [],
      confidentialityType: false,
      option: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            ["bold", "italic", "underline", "strike"], // toggled buttons

            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
          ],
        },
      },
      streetOrLane: [
        { value: "St", text: "Street", name: "Street" },
        { value: "Ln", text: "Lane", name: "Lane" },
      ],
      streetLane: null,
      prefix: "",
      armyNo: "",
      cnic: "",
      name: "",
      address: "",
      district: "",
      contactNo: "",
      benefit_type: null,
      isServing: true,
      isCommercial: false,
      svc_benefit_type: "",
      regiment: "",
      plot_size: "",
      plot_society: "",
      plot_location: "",
      plot_no: "",
      street: "",
      sector: "",
      phase: "",
      detailForm: false,
      districts: [],
      plotSizes: [],
      benefitTypeList: [],
      benefitNumbers: [],
      societyList: [],

      type: { value: 5, name: "User Generated AFP" },

      // svcStatusList: [],

      priority: { value: "Normal", name: "Normal" },
      priorities: [
        { value: "Normal", name: "Normal" },
        { value: "Flash", name: "Flash" },
      ],
    };
  },
  async mounted() {
    await this.getNocFormFields();
    if (this.nocData) {
      this.cnic = this.nocData.cnic;
      this.name = this.nocData.name;
      this.contactNo = this.nocData.contact_no;
      this.address = this.nocData.address;
      this.district = this.nocData.district;
      this.benefit_type = this.nocData.benefit_type;
      this.svc_benefit_type = this.nocData.svc_benefit_type;
      this.regiment = this.nocData.regiment;
      this.plot_size = this.nocData.plot_size;
      this.plot_society = this.nocData.plot_society;
      this.plot_location = this.nocData.plot_location;
      this.plot_no = this.nocData.plot_no;
      this.street = this.nocData.street;
      this.sector = this.nocData.sector;
      this.phase = this.nocData.phase;
      this.isServing = this.nocData.is_serving;
      this.isCommercial = this.nocData.is_commercial;
      this.draft = false;

      this.currently_marked = 99999999;
    }
  },

  methods: {
    ...mapActions({
      partialUpdateNoc: "appData/partialUpdateENoc",
      getNOCFields: "appData/getNOCFields",
    }),
    async getNocFormFields() {
      try {
        const res = await this.getNOCFields();

        if (res.status === 200) {
          this.districts = res.data.districts;
          this.plotSizes = res.data.plot_sizes;
          // this.corps = res.data.regiments;
          this.societyList = res.data.plot_societies;
          // this.benefitTypeList = res.data.benefit_types;
          // this.benefitNumbers = res.data.svc_benefit_types;
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    async submit() {
      try {
        let plotType = 1;
          if(this.isCommercial){
            plotType = 2;
          }else{
            plotType = 1;
          }
        this.show = true;
        const res = await this.partialUpdateNoc({
          id: this.nocData.id,
          title: this.title,
          subject: this.subject,
          content: this.content,
          type: this.type.value,
          draft: this.draft,
          history: history,

          cnic: this.cnic,
          name: this.name,
          address: this.address,
          district_id: this.district.id,
          contact_no: this.contactNo,

          plot_size_id: this.plot_size.id,
          plot_society_id: this.plot_society.id,
          plot_location: this.plot_location,
          plot_no: this.plot_no,
          street: this.street,
          sector: this.sector,
          phase: this.phase,
          plot_type: plotType,

          is_serving: false,
          is_commercial: this.isCommercial,
          draft: this.draft,

          currently_marked: 99999999,
          priority: this.priority.value,
          updated_by: this.getLoggedInUser.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: res.data.msg,
            icon: "success",
            timer: 1500,
          });
          this.show = false;
          this.$nextTick(() => {
            this.$bvModal.hide("enoc-edit-modal");
          });
          this.$emit("modalClosed");
        } else {
          this.$swal({
            title: "Unable to update NOC",
            icon: "error",
            timer: 3000,
          });
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    async validationForm() {
      const success = await this.$refs.nocEditValidation.validate();
      if (success) {
        await this.submit();
      } else {
        this.$swal({
          title: "Please fill out all required fields",
          icon: "error",
          timer: 3000,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      getLoggedInUser: "appData/getUser",
    }),
  },
};
</script>

<style scoped>
[hidden] {
  display: none !important;
}

.wrapper {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0px;
  text-align: center;
}

/* label {
  font-size: 110%;
  font-weight: 550 !important;
  color: #000;
} */
</style>
