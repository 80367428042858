<template>
  <b-modal
    id="noc-add-document-modal"
    title="Add Document to Noc"
    centered
    hide-footer
    size="md"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Add Documents to Noc</h2>
    </template>
    <validation-observer ref="nocDocumentsAddValidation">
      <b-form @submit.prevent>
        <b-form-group label="Attachments" label-for="attachments">
          <b-form-file
            id="attachments"
            placeholder="Choose files or drop them here..."
            drop-placeholder="Drop file here..."
            multiple
            v-model="uploadedFiles"
            :accept="acceptedFileTypes"
          />
        </b-form-group>
        <b-form-group>
          <b-button
            type="submit"
            variant="primary"
            pill
            class="mr-1 float-right"
            @click="validationForm"
          >
            Submit
          </b-button>
          <b-button
            type="reset"
            variant="outline-secondary"
            @click="reset"
            class="mr-1 float-right"
            pill
          >
            Reset
          </b-button>
          
        </b-form-group>
      </b-form>
    </validation-observer>
  <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
  
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [util],
  props: { nocId: Number },
  data() {
    return {
      show : false,
      required,
      uploadedFiles: null,
      acceptedFileTypes: ".pdf, .jpg, .png, .docx, .xlsx",
    };
  },
  methods: {
    ...mapActions({
      addDocumentsToNoc: "appData/addDocumentsToNoc",
    }),
    async validationForm() {
      const success = await this.$refs.nocDocumentsAddValidation.validate();
      if (success) {
        let invalidType = false;
        try{
          for (let i = 0; i < this.uploadedFiles.length; i++) {
          if (
            this.acceptedFileTypes.indexOf(
              this.uploadedFiles[i].name.split(".")[1]
            ) < 0
          ) {
            invalidType = true;
            break;
          }
        }
        }
        catch(e){
          
        }
        if (!invalidType) {
          await this.submit();
        }
      }
    },
    async submit() {
        this.show = true;

      try {
        let formData = new FormData();
        this.uploadedFiles.map((file, index) => {
          formData.append(`file${index}`, file);
        });
        const res = await this.addDocumentsToNoc({
          formData: formData,
          id: this.nocId,
        });
        if (res.status === 200) {
          this.$swal({
            title: res.data.msg,
            icon: "success",
            timer: 1500,
          });
          this.show = false;
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
      this.$emit("modalClosed");
    },
    reset() {},
  },
  computed: {
    ...mapGetters({ getUser: "appData/getUser" }),
  },
};
</script>
